@font-face {
  font-family: "JohnsonDisplay-BoldItalic";
  src: url("../public/fonts/JohnsonDisplay-BoldItalic.otf") format("opentype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonDisplay-Light";
  src: url("../public/fonts/JohnsonDisplay-Light.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonDisplay-LightItalic";
  src: url("../public//fonts/JohnsonDisplay-LightItalic.otf") format("opentype");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonDisplay-MediumItalic";
  src: url("../public//fonts/JohnsonDisplay-MediumItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonDisplay-Regular";
  src: url("../public/fonts/JohnsonDisplay-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonDisplay-Regular";
  src: url("../public/fonts/JohnsonDisplay-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonText-Light";
  src: url("../public/fonts/JohnsonText-Light.otf") format("opentype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonText-LightItalic";
  src: url("../public/fonts/JohnsonText-LightItalic.woff") format("woff");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonText-LightItalic";
  src: url("../public/fonts/JohnsonText-LightItalic.woff2") format("woff2");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonText-Medium";
  src: url("../public/fonts/JohnsonText-Medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonText-MediumItalic";
  src: url("../public/fonts/JohnsonText-MediumItalic.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonText-MediumItalic";
  src: url("../public/fonts/JohnsonText-MediumItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "JohnsonText-Regular";
  src: url("../public/fonts/JohnsonText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonText-Regular";
  src: url("../public/fonts/JohnsonText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonText-Regular";
  src: url("../public/fonts/JohnsonText-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "JohnsonText-RegularItalic";
  src: url("../public/fonts/JohnsonText-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
