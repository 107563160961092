html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

:root {
  --bs-link-color: rgb(235, 23, 0);
  /* Set your desired color value */
}

.main-container {
  max-width: 1000px;
  margin: 0 auto;
}

@media(min-width: 1250px){
  .main-container {
    max-width: 1200px;
  }
}

.search-container {
  max-width: 1000px;
  margin: 0 auto;
  min-height: 600px;
  padding: 24px 8px;
}

.search-container * {
  margin: 8px 0;
}

.legal-container {
  max-width: 1000px;
  padding: 24px 8px;
  margin: 0 auto;
  min-height: 600px;
}

.topbar {
  display: flex;
  height: 80px;
  background-color: white;
  padding: 0px 40px;
  align-items: center;
}

.topbar h1 {
  color: red;
}

.selector-frame {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background-color: #e8e6e3;
  justify-content: space-between;
  padding: 7px 16px;
}
.selector-frame > div:first-child{
  max-width: 670px;
}
@media(min-width: 1250px){
  .selector-frame > div:first-child{
    max-width: 790px;
  }
}

.hover-input {
  &:hover {
    background-color: #0000001a;
    cursor: initial;
    outline: none;
  }

  &::placeholder {
    color: white;
  }

  &:focus {
    outline: none;
  }
}

.custom-select-language {
  border: none;
  width: fit-content;
  height: 24px;
  background-color: #e8e6e3;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.language-list-with-margin {
  margin-right: 8px;
}

.custom-select-country {
  border: none;
  width: fit-content;
  height: 24px;
  background-color: #e8e6e3;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

fieldset {
  border: none !important;
}

.outer-container {
  border-bottom: dotted #d2dce6;
}

.mob-footer {
  padding: 24px 24px;
  /* max-width: fit-content; */
}

@media (min-width:1272px) and (max-width: 1350px) {
  .footer-text {
    font-size: 15px;
  }
  .footer-links{
    font-size:15px;
  }
}

@media (min-width:1090px) and (max-width: 1271px) {
  #footerLogo{
    width: 97px;
  }
  .footer-text {
    font-size: 12.5px;
  }
  .footer-links{
    font-size:12.5px;
  }
}

@media (max-width: 1089px) {
  #footerLogo{
    width: 97px;
  }
  .footer-text {
    font-size: 13px;
  }
  .footer-links{
    font-size:13px;
  }
}

.scandit .scandit-laser img {
  display: none;
}

.scan-window {
  width: '1440px';
  height: '768px';
}

@media (min-width: 900px) {
  .css-1p7aq4p-MuiStack-root > :not(style) ~ :not(style) {
    margin-top: 0 !important;
    /* Set margin-top to 0 and use !important to override existing styles */
  }
  .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    background-color: rgb(249, 248, 247);
  }
}

@media (min-width: 900px) {
  .css-6u7f5s-MuiStack-root > :not(style) ~ :not(style) {
    margin-left: 0 !important;
  }
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
}

#scandit-window {
  top: 0;
  left: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

/* Landscape & Portrait media query */
/* @media screen and (orientation:landscape) {
    #scandit-window{
        width: 100%;
        height: 100vw;
    }
}

@media screen and (orientation:portrait) {
    #scandit-window{
        width: 100%;
        height: 100vh;
    }
} */

@media(min-width: 900px) {
  .main-content{
    margin-top: 50px;
  }
}
