/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
    margin: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
}

h1 {
    mso-style-link: "Heading 1 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 0cm;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    font-weight: normal;
}

h2 {
    mso-style-link: "Heading 2 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    font-weight: normal;
}

h3 {
    mso-style-link: "Heading 3 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 43.2pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    font-weight: normal;
}

h4 {
    mso-style-link: "Heading 4 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 64.8pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    font-weight: normal;
}

h5 {
    mso-style-link: "Heading 5 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 86.4pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    font-weight: normal;
}

h6 {
    mso-style-link: "Heading 6 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 108.0pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    font-weight: normal;
}

p.MsoHeading7,
li.MsoHeading7,
div.MsoHeading7 {
    mso-style-link: "Heading 7 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 129.6pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
}

p.MsoHeading8,
li.MsoHeading8,
div.MsoHeading8 {
    mso-style-link: "Heading 8 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 151.2pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoHeading9,
li.MsoHeading9,
div.MsoHeading9 {
    mso-style-link: "Heading 9 Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 172.8pt;
    text-indent: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoHeader,
li.MsoHeader,
div.MsoHeader {
    mso-style-link: "Header Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 24.0pt;
    margin-left: 0cm;
    text-align: right;
    line-height: 105%;
    font-size: 11.0pt;
    
    color: red;
    text-transform: uppercase;
    letter-spacing: .2pt;
    font-weight: bold;
}

p.MsoHeaderCxSpFirst,
li.MsoHeaderCxSpFirst,
div.MsoHeaderCxSpFirst {
    mso-style-link: "Header Char";
    margin: 0cm;
    text-align: right;
    line-height: 105%;
    font-size: 11.0pt;
    
    color: red;
    text-transform: uppercase;
    letter-spacing: .2pt;
    font-weight: bold;
}

p.MsoHeaderCxSpMiddle,
li.MsoHeaderCxSpMiddle,
div.MsoHeaderCxSpMiddle {
    mso-style-link: "Header Char";
    margin: 0cm;
    text-align: right;
    line-height: 105%;
    font-size: 11.0pt;
    
    color: red;
    text-transform: uppercase;
    letter-spacing: .2pt;
    font-weight: bold;
}

p.MsoHeaderCxSpLast,
li.MsoHeaderCxSpLast,
div.MsoHeaderCxSpLast {
    mso-style-link: "Header Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 24.0pt;
    margin-left: 0cm;
    text-align: right;
    line-height: 105%;
    font-size: 11.0pt;
    
    color: red;
    text-transform: uppercase;
    letter-spacing: .2pt;
    font-weight: bold;
}

p.MsoFooter,
li.MsoFooter,
div.MsoFooter {
    mso-style-link: "Footer Char";
    margin-top: 24.0pt;
    margin-right: 0cm;
    margin-bottom: 0cm;
    margin-left: 0cm;
    text-align: center;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoListBullet,
li.MsoListBullet,
div.MsoListBullet {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 21.6pt;
    text-indent: -21.6pt;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoListBullet2,
li.MsoListBullet2,
div.MsoListBullet2 {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 43.2pt;
    text-indent: -12pt;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoListBullet3,
li.MsoListBullet3,
div.MsoListBullet3 {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 64.8pt;
    text-indent: -21.6pt;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoListBullet4,
li.MsoListBullet4,
div.MsoListBullet4 {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 86.4pt;
    text-indent: -21.6pt;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoListBullet5,
li.MsoListBullet5,
div.MsoListBullet5 {
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 108.0pt;
    text-indent: -21.6pt;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.MsoTitle,
li.MsoTitle,
div.MsoTitle {
    mso-style-link: "Title Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 18.0pt;
    margin-left: 0cm;
    text-align: center;
    line-height: 105%;
    page-break-after: avoid;
    font-size: 11.0pt;
    
    text-transform: uppercase;
    letter-spacing: .3pt;
    font-weight: bold;
}

p.MsoTitleCxSpFirst,
li.MsoTitleCxSpFirst,
div.MsoTitleCxSpFirst {
    mso-style-link: "Title Char";
    margin: 0cm;
    text-align: center;
    line-height: 105%;
    page-break-after: avoid;
    font-size: 11.0pt;
    
    text-transform: uppercase;
    letter-spacing: .3pt;
    font-weight: bold;
}

p.MsoTitleCxSpMiddle,
li.MsoTitleCxSpMiddle,
div.MsoTitleCxSpMiddle {
    mso-style-link: "Title Char";
    margin: 0cm;
    text-align: center;
    line-height: 105%;
    page-break-after: avoid;
    font-size: 11.0pt;
    
    text-transform: uppercase;
    letter-spacing: .3pt;
    font-weight: bold;
}

p.MsoTitleCxSpLast,
li.MsoTitleCxSpLast,
div.MsoTitleCxSpLast {
    mso-style-link: "Title Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 18.0pt;
    margin-left: 0cm;
    text-align: center;
    line-height: 105%;
    page-break-after: avoid;
    font-size: 11.0pt;
    
    text-transform: uppercase;
    letter-spacing: .3pt;
    font-weight: bold;
}

p.MsoBodyText,
li.MsoBodyText,
div.MsoBodyText {
    mso-style-link: "Body Text Char";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    
}

a {
    color: black;
}

a:link,
span.MsoHyperlink {
    color: #eb1700;
    text-decoration: underline;
}

span.BodyTextChar {
    mso-style-name: "Body Text Char";
    mso-style-link: "Body Text";
    
}

span.EmphasisLegal {
    /* mso-style-name: "Emphasis \(Legal\)";
    font-variant: small-caps;
    letter-spacing: .6pt; */
}

span.FooterChar {
    mso-style-name: "Footer Char";
    mso-style-link: Footer;
    
}

span.HeaderChar {
    mso-style-name: "Header Char";
    mso-style-link: Header;
    
    color: red;
    text-transform: uppercase;
    letter-spacing: .2pt;
    font-weight: bold;
}

span.Heading1Char {
    mso-style-name: "Heading 1 Char";
    mso-style-link: "Heading 1";
}

p.HeadingParagraph1,
li.HeadingParagraph1,
div.HeadingParagraph1 {
    mso-style-name: "Heading Paragraph 1";
    margin-top: 0cm;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 0cm;
    line-height: 105%;
    font-size: 11.0pt;
    
}

p.HeadingTitle1,
li.HeadingTitle1,
div.HeadingTitle1 {
    mso-style-name: "Heading Title 1";
    mso-style-link: "Heading Title 1 Char";
    margin-top: 18.0pt;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 0cm;
    line-height: 105%;
    page-break-after: avoid;
    font-size: 11.0pt;
    
    letter-spacing: .2pt;
    font-weight: bold;
}

span.HeadingTitle1Char {
    mso-style-name: "Heading Title 1 Char";
    mso-style-link: "Heading Title 1";
    
    letter-spacing: .2pt;
    font-weight: bold;
}

p.HeadingTitleLegalEmphasis,
li.HeadingTitleLegalEmphasis,
div.HeadingTitleLegalEmphasis {
    mso-style-name: "Heading Title \(Legal Emphasis\)";
    mso-style-link: "Heading Title \(Legal Emphasis\) Char";
    margin-top: 18.0pt;
    margin-right: 0cm;
    margin-bottom: 12.0pt;
    margin-left: 0cm;
    line-height: 105%;
    page-break-after: avoid;
    font-size: 11.0pt;
    
    /* font-variant: small-caps;
    letter-spacing: .3pt; */
    font-weight: bold;
}

span.HeadingTitleLegalEmphasisChar {
    mso-style-name: "Heading Title \(Legal Emphasis\) Char";
    mso-style-link: "Heading Title \(Legal Emphasis\)";
    
    /* font-variant: small-caps;
    letter-spacing: .3pt; */
    font-weight: bold;
}

span.TitleChar {
    mso-style-name: "Title Char";
    mso-style-link: Title;
    text-transform: uppercase;
    letter-spacing: .3pt;
    font-weight: bold;
}

span.normaltextrun {
    mso-style-name: normaltextrun;
}

.MsoChpDefault {
    font-size: 10.0pt;
}

/* Page Definitions */
@page WordSection1 {
    size: 612.0pt 792.0pt;
    margin: 72.0pt 72.0pt 72.0pt 72.0pt;
}

div.WordSection1 {
    page: WordSection1;
}

/* List Definitions */
ol {
    margin-bottom: 0cm;
}

ul {
    margin-bottom: 0cm;
}

.dots {
    display: none;
}